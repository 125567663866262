import React, { useEffect, useState } from 'react';
import { ProgressBarWrapper } from '../styles';

const ProgressBar = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setWidth(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <ProgressBarWrapper style={{ width: `${width}%` }} />;
};

export default ProgressBar;
