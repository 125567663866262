import React from 'react';
import Section from '../components/Section';
import AnimatedCircuit from '../components/AnimatedCircuit';

const About = () => (
  <Section id="about" title="Sobre mí">
    <AnimatedCircuit />
    <h1>Jonathan Alexander Grenald Polo</h1>
    <p className="role">Ingeniero de Ciberseguridad | Ingeniero de Infraestructura | Administrador de sistemas</p>
    <p className="contact">
    <a href="https://www.linkedin.com/in/jonathangrenald/?locale=es_ES" target="_blank">LinkedIn</a>
    </p>
    <p>Como un apasionado profesional de TI, estoy emocionado de traer mis habilidades y experiencia a una nueva oportunidad. Con más de 8 años de experiencia trabajando con equipos de redes e infraestructura de TI, he desarrollado una comprensión profunda de cómo la tecnología puede agregar valor a una organización, y tengo un historial comprobado de resolución de problemas críticos con agilidad metódica.</p>
    <p>Una de las cosas que más me gustan de trabajar en TI es la oportunidad de colaborar con otros, y me enorgullezco de mi capacidad para comunicarme de manera efectiva con las partes interesadas tanto técnicas como no técnicas. Ya sea que esté trabajando en una arquitectura de red compleja o solucionando un problema del sistema, siempre me enfoco en brindar resultados de alta calidad y superar las expectativas.</p>
  </Section>
);

export default About;
