import React from 'react';
import PropTypes from 'prop-types';
import './Timeline.css';

const Timeline = ({ experiences }) => (
  <div className="timeline">
    {experiences.map((experience, index) => (
      <div className="timeline-item" key={index}>
        <span className="date">{experience.date}</span>
        <div className="timeline-content">
          <h3>{experience.title}</h3>
          <p>{experience.description}</p>
        </div>
      </div>
    ))}
  </div>
);

Timeline.propTypes = {
  experiences: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Timeline;
