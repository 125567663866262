import React from 'react';
import Section from '../components/Section';
import SkillCard from '../components/SkillCard';
import styled from 'styled-components';

const SkillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px; // Espaciamos las tarjetas
`;

const skillsData = [
  {
    name: 'Windows',
    icon: 'https://www.svgrepo.com/show/382713/windows-applications.svg',
    details: ['Administracion de Windows Server', 'Active Directory', 'Group Policy', 'DHCP', 'File Servers', 'Sharepoint', 'Office 365'],
    bgColor: 'rgba(0, 123, 255, 0.8)',
  },
  {
    name: 'Linux',
    icon: 'https://www.svgrepo.com/show/354004/linux-tux.svg',
    details: ['Distros basadas en Debian', 'Distros basados en Red Hat', 'Bash Scripting', 'Samba', 'Revisión de Logs', 'Servidores Web Apache', 'Revisión de Seguridad', 'Balance de Cargas'],
    bgColor: 'rgba(40, 167, 69, 0.8)',
  },
  {
    name: 'Redes',
    icon: 'https://www.svgrepo.com/show/477112/internet.svg',
    details: ['Firewalls', 'Routing', 'Switching', 'Wireless Networking', 'Análisis de tráfico con Wireshark', 'Troubleshooting de redes', 'Seguridad en dispositivos de red', 'Segmentación de red'],
    bgColor: 'rgba(255, 193, 7, 0.8)',
  },
  {
    name: 'Virtualización',
    icon: 'https://www.svgrepo.com/show/331777/virtual-machine.svg',
    details: ['VMware', 'Hyper-V', 'VirtualBox', 'Dockers'],
    bgColor: 'rgba(220, 53, 69, 0.8)',
  },
  {
    name: 'Ciberseguridad',
    icon: 'https://www.svgrepo.com/show/271914/security-system-lock.svg',
    details: ['Seguridad del Endpoint', 'Seguridad en profundidad', 'Análisis de Vulnerabilidades', 'Implementación de Políticas', 'Mejora continua'],
    bgColor: 'rgba(108, 117, 125, 0.8)',
  },
  {
    name: 'Soft Skills',
    icon: 'https://www.svgrepo.com/show/281701/teamwork-transfer.svg',
    details: ['Comunicación efectiva', 'Liderazgo', 'Trabajo en equipo', 'Pensamiento Crítico', 'Creatividad e innovación'],
    bgColor: 'rgba(255, 159, 64, 0.8)',
  },
];

const Skills = () => (
  <Section id="skills" title="Habilidades y Competencias">
    <SkillsWrapper>
      {skillsData.map(skill => (
        <SkillCard key={skill.name} skill={skill} bgColor={skill.bgColor} />
      ))}
    </SkillsWrapper>
  </Section>
);

export default Skills;
