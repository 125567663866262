import React, { useState, useEffect } from 'react';
import Section from '../components/Section';
import styled from 'styled-components';

const HiddenContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

const References = () => {
  const [authorized, setAuthorized] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [code, setCode] = useState('');
  const correctCode = '835690';

  useEffect(() => {
    const savedAttempts = parseInt(localStorage.getItem('attempts'), 10);
    if (savedAttempts >= 3) {
      setAttempts(savedAttempts);
    }
  }, []);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (code === correctCode) {
      setAuthorized(true);
      localStorage.removeItem('attempts');
    } else {
      const newAttempts = attempts + 1;
      setAttempts(newAttempts);
      localStorage.setItem('attempts', newAttempts.toString());
      if (newAttempts >= 3) {
        alert('Demasiados intentos. Estás bloqueado de ver esta información.');
      } else {
        alert('Código incorrecto. Por favor, intenta nuevamente.');
      }
    }
  };

  return (
    <Section id="references" title="Referencias">
      {!authorized && attempts < 3 && (
        <>
          <p>Solicitar código para ver referencias y contactos privados.</p>
          <form onSubmit={handleCodeSubmit}>
            <label>
              Ingresar código de acceso:
              <input type="password" value={code} onChange={handleCodeChange} />
            </label>
            <button type="submit">Enviar</button>
          </form>
        </>
      )}
      <HiddenContent show={authorized}>
        <h3>Contactos Privados</h3>
        <p><strong>Email:</strong> jonathangrenald23@gmail.com</p>
        <p><strong>Celular:</strong> +507 6940-0001</p>
      </HiddenContent>
      {!authorized && attempts >= 3 && (
        <p>Demasiados intentos. Estás bloqueado de ver esta información.</p>
      )}
      {authorized && (
        <>
          <h3>Referencias</h3>
          <p>Nombre: Anselmo Girón<br/> Cargo: Jefe de soporte técnico<br/> Empresa: Mays Zona Libre<br/> Teléfono: +507 6948-5960<br/> </p>
          <p>Nombre: Rogelio Vargas<br/> Cargo: Coordinador IT<br/> Empresa: Colon Import (JCain)<br/> Teléfono: +507 6218-1023<br/> </p>
          <p>Nombre: Donaldson Castros<br/> Cargo: Encargado de Sistemas<br/> Empresa: Cancillería<br/> Teléfono: +507 6674-5527<br/></p>
          <p>Nombre: Gloria Gonzalez<br/> Cargo: Gerenta de Cuentas<br/> Empresa: SONDA<br/> Teléfono: +507 6678-2008<br/> </p>
        </>
      )}
    </Section>
  );
};

export default References;
