import React from 'react';
import { SidebarWrapper, NavLink } from '../styles';

const Sidebar = ({ showSidebar, toggleSidebar }) => (
  <SidebarWrapper showSidebar={showSidebar}>
    <button onClick={toggleSidebar} style={{ margin: '10px' }}>✖</button>
    <ul className="nav flex-column">
      <li className="nav-item"><NavLink href="#about">Sobre mí</NavLink></li>
      <li className="nav-item"><NavLink href="#skills">Habilidades</NavLink></li>
      <li className="nav-item"><NavLink href="#experience">Experiencia</NavLink></li>
      <li className="nav-item"><NavLink href="#education">Educación</NavLink></li>
      <li className="nav-item"><NavLink href="#achievements">Logros</NavLink></li>
      <li className="nav-item"><NavLink href="#languages">Idiomas</NavLink></li>
      <li className="nav-item"><NavLink href="#references">Referencias</NavLink></li>
    </ul>
  </SidebarWrapper>
);

export default Sidebar;
