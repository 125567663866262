import React from 'react';
import Section from '../components/Section';
import styled from 'styled-components';

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const Education = () => (
  <Section id="education" title="Educación">
    <h3>Diplomas Universitarios</h3>
    <List>
      <li>Postgrado en Alta Gerencia, Universidad Latina de Panamá (En curso hasta 2024 octubre, virtual)</li>
      <li>Máster en ingeniería de Software, Universidad de Tianjin, China (septiembre de 2017)</li>
      <li>Licenciado en Redes Informáticas, Universidad Tecnológica de Panamá (abril de 2014)</li>
    </List>
    <h3>Certificados</h3>
    <List>
      <li>Certificación de Ciberseguridad, IRSI – SISAP (Guatemala) – En progreso hasta 2024 diciembre</li>
      <li>Bootcamp SOC Analyst Nivel 1, Comunidad Dojo (octubre 2023)</li>
      <li>Curso Microsoft Azure Cybersecurity Architect SC-100, Tecnasa S.A (octubre 2023)</li>
      <li>Curso Microsoft Azure Administrator AZ-104, Tecnasa S.A (octubre 2023)</li>
      <li>Oracle Cloud Foundations Associate, Oracle (septiembre 2023)</li>
      <li>Blue Team Junior Analyst Pathway, Security Blue Team, (agosto 2023)</li>
      <li>Fundamentos de Ciberseguridad, Google (abril 2023)</li>
      <li>CCNA (Certificación Cisco), (abril de 2014)</li>
    </List>
  </Section>
);

export default Education;
