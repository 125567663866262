import React from 'react';
import { useSpring, animated } from 'react-spring';
import './AnimatedCircuit.css';

const AnimatedCircuit = () => {
  const { x } = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 2000 },
    loop: { reverse: true }
  });

  return (
    <div className="circuit-container">
      <svg className="circuit" viewBox="0 0 100 100">
        <animated.circle cx={x.to(x => `${x * 50 + 50}%`)} cy="50" r="1" />
        <animated.circle cx={x.to(x => `${x * 40 + 30}%`)} cy="50" r="1" />
        <animated.circle cx={x.to(x => `${x * 30 + 10}%`)} cy="50" r="1" />
        <animated.circle cx={x.to(x => `${x * 20}%`)} cy="50" r="1" />
      </svg>
    </div>
  );
};

export default AnimatedCircuit;
