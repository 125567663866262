import React, { useState } from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  perspective: 1000px;
  margin: 20px;
  position: relative;

  &:hover .tooltip {
    opacity: 1;
  }
`;

const Card = styled.div`
  width: 250px;
  height: 250px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
  transform: ${props => (props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)')};
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: ${props => props.bgColor};
  color: white;
`;

const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: ${props => props.bgColor};
  color: white;
  transform: rotateY(180deg);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SkillDetails = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
`;

const SkillCard = ({ skill, bgColor }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <CardContainer onClick={toggleFlip}>
      <Tooltip className="tooltip">{skill.name}</Tooltip>
      <Card isFlipped={isFlipped}>
        <CardFront bgColor={bgColor}>
          <img src={skill.icon} alt={skill.name} style={{ width: '100px', height: '100px' }} />
        </CardFront>
        <CardBack bgColor={bgColor}>
          <h3>{skill.name}</h3>
          <SkillDetails>
            {skill.details.map(detail => (
              <li key={detail}>{detail}</li>
            ))}
          </SkillDetails>
        </CardBack>
      </Card>
    </CardContainer>
  );
};

export default SkillCard;
