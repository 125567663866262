import React from 'react';
import Section from '../components/Section';
import Timeline from '../components/Timeline';

const experiences = [
  {
    date: "Enero 2024 - Presente",
    title: "Oficial de seguridad informática, Galindo, Arias & López",
    description: "Administración de firewall, DLP, encriptación de datos y dispositivos móviles, localización de equipos, inventario, liderazgo de equipo de soporte, punto de contacto de SOC, actualización de equipos, creación de políticas e inicio de implementación de ISO 27001."
  },
  {
    date: "Octubre 2021 - Enero 2024",
    title: "Ingeniero sénior de aplicaciones e infraestructura de TI, Mays Zona Libre",
    description: "Mantener la infraestructura en funcionamiento sin problemas, asegurando que se desempeñe de la mejor manera. Implementé WSUS, soluciones de monitoreo para servidores y almacenamiento, agregué una herramienta de administración de activos para la organización y preparé planes programados para actualizaciones de firmware y hardware. Configuración de Samba, NFS, Web Apps, entre otros."
  },
  {
    date: "Septiembre 2021 - Octubre 2021",
    title: "Ingeniero de Servidores Linux y Windows, El Orbe (TIGO como cliente)",
    description: "Administración de más de 700+ Servidores entre servidores físicos y virtuales. Ser reconocido por los trabajadores internos de TI como un activo importante."
  },
  {
    date: "Febrero 2017 - Julio 2017",
    title: "Ingeniero de sistemas, Ministerio de Relaciones Exteriores de Panamá",
    description: "Administración de Servidores Windows y Linux, configuraciones de Cisco Networking y configuraciones de firewall. Gestión y control de presupuestos de ISPs y otros proveedores de servicios."
  },
  {
    date: "Diciembre 2013 - Febrero 2017",
    title: "Ingeniero de Infraestructura y Redes (Jefe de Soporte Técnico), Colón Importación y Exportación",
    description: "Administrar un equipo de soporte técnico, asegurando que brinden soporte de alta calidad a los usuarios finales dentro de la organización. Supervisar el diseño, la implementación y el mantenimiento de la infraestructura de TI de la organización."
  },
  {
    date: "Febrero 2012 - Diciembre 2013",
    title: "Soporte técnico, Metrobank SA",
    description: "Mantenimiento y configuración de computadoras, Wi-Fi e impresoras dentro de SLA. Implementación de routers, endpoint DLP, WSUS y Cisco para todas las sucursales de la organización. Migración de nuestros principales data centers a data centers externos tier3."
  }
];

const Experience = () => (
  <Section id="experience" title="Experiencia Laboral">
    <Timeline experiences={experiences} />
  </Section>
);

export default Experience;
