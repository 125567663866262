import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Section from '../components/Section';
import './Languages.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const languages = [
  { label: 'Español', data: 100, color: 'rgba(54, 162, 235, 0.6)' },
  { label: 'Inglés', data: 80, color: 'rgba(75, 192, 192, 0.6)' },
  { label: 'Mandarín', data: 40, color: 'rgba(255, 206, 86, 0.6)' },
  { label: 'Indonesio', data: 20, color: 'rgba(153, 102, 255, 0.6)' },
];

const Languages = () => (
  <Section id="languages" title="Idiomas">
    <div className="languages-container">
      {languages.map((language) => (
        <div key={language.label} className="language-chart">
          <h3>{language.label}</h3>
          <Doughnut
            data={{
              labels: [language.label],
              datasets: [
                {
                  data: [language.data, 100 - language.data],
                  backgroundColor: [
                    language.color,
                    'rgba(200, 200, 200, 0.3)',
                  ],
                  borderColor: [
                    language.color,
                    'rgba(200, 200, 200, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              cutout: '70%',
            }}
          />
        </div>
      ))}
    </div>
  </Section>
);

export default Languages;
