import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.showSidebar ? '250px' : '0')};
  transition: margin-left 0.3s ease-in-out;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SectionWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-bottom: 20px;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.showSidebar ? 'translateX(0)' : 'translateX(-100%)')};

  @media (max-width: 768px) {
    transform: translateX(-100%);
  }
`;

export const NavLink = styled.a`
  display: block;
  padding: 15px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background-color: rgba(0, 0, 255, 0.3);
  }
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 5px;
  background: linear-gradient(to right, #00f260, #0575e6);
  z-index: 999;
`;
