import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ProgressBar from './components/ProgressBar';
import About from './pages/About';
import Skills from './pages/Skills';
import Experience from './pages/Experience';
import Education from './pages/Education';
import Achievements from './pages/Achievements';
import Languages from './pages/Languages';
import References from './pages/References';
import { Container } from './styles';

function App() {
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <Router>
      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
      <button
        onClick={toggleSidebar}
        style={{
          position: 'fixed',
          top: '10px',
          left: showSidebar ? '250px' : '10px',
          zIndex: 1000,
          background: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        ☰
      </button>
      <ProgressBar />
      <Container showSidebar={showSidebar}>
        <About />
        <Skills />
        <Experience />
        <Education />
        <Achievements />
        <Languages />
        <References />
      </Container>
    </Router>
  );
}

export default App;
