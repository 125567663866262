import React from 'react';
import Section from '../components/Section';

const Achievements = () => (
  <Section id="achievements" title="Logros">
    <ul>
      <li>Implementación de infraestructura crítica para Galindo, Arias & López.</li>
      <li>Migración de sistemas VMware de 6.5 U1 a 6.7U3 en Mays Zona Libre.</li>
    </ul>
  </Section>
);

export default Achievements;
